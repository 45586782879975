<template>
    <div ref="drop-down-filter"
         class="r-filter-cell"
         :class="[{'_removable': isRemovable, '_changed' : proxyValue && proxyValue.length && (proxyValue[0] && proxyValue[0].pk !== 'any') || !multiple && proxyValue && !Array.isArray(proxyValue), '_placeholder' : placeholder, '_opened' : opened, '_hide' : facets.length === 0 || disabled, '_searchable': isSearchable}, blockClass]">
        <div v-if="label"
             class="r-filter-cell__label"
             :class="{'_hide' : facets.length === 0}">{{ label }}
        </div>

        <div class="r-filter-cell__bottom"
             :class="[
                {
                    '_changed' : proxyValue && proxyValue.length,
                    '_hide' : facets.length === 0,
                    '_with-error': withError,
                    '_wrong': withError && errors && errors[name] && errors[name][0],
                    '_search': isSearchable
                },
                defineClass,
            ]">

            <multiselect
                ref="multiselect"
                v-model="value"
                :options="isAjaxSearchable ? ajaxOptions : facets"
                :show-labels="false"
                :show-pointer="false"
                :hide-selected="false"
                :searchable="isSearchable"
                :multiple="multiple"
                :close-on-select="closeOnSelect"
                :placeholder="statePlaceholder"
                :open-direction="openBottom ? 'bottom' : ''"
                :label="labelName"
                :track-by="trackBy"
                :internal-search="internalSearch"
                :clear-on-select="multiple"
                :show-no-results="isNoResult"
                :selected-label="'any'"
                :allow-empty="allowEmpty"
                @input="changeHandler"
                @open="openSelectList"
                @close="opened = !opened"
                @select="optionSelectHandler"
                @search-change="searchHandler"
            >

                <template slot="selection" slot-scope="{ values, search, isOpen }">
                    <div v-if="proxyValue && proxyValue.length && multiple" ref="placeHolder" class="select__labels"
                         :class="{'_hide' : facets.length === 0}">
                        {{ valueList }}
                    </div>
                </template>
              <template v-if="isRemovable" slot="option" slot-scope="props">
               <div class="d-flex justify-content-between removable-el align-items-center">
                 <p>{{props.option.name}}</p>
                 <feather-icon v-if="!props.option.is_default" class="input-cancel cursor-pointer" icon="XIcon" size="16" @click="remove($event, props.option.id, props)"/>
                 <BSpinner v-show="props.option.id === -1" class="input-spinner" variant="primary" small />
               </div>
              </template>
                <template slot="noResult">
                    <div>{{ noResultText }}</div>
                </template>
              <template v-if="noOptionsText" slot="noOptions">
                <div>{{ noOptionsText }}</div>
              </template>
                <template v-if="isSearchable" slot="caret" slot-scope="{ toggle }">
                    <div
                         :class="['multiselect__search-clear', {'_active': searchValue}]"
                    ></div>
                  <div v-if="isSearching" class="multiselect__spinner"></div>
                    <div class="multiselect__select"
                         @mousedown.prevent.stop="toggle()"
                    >
                    </div>
                </template>

            </multiselect>

            <div v-if="withError && errors && errors[name] && errors[name][0]"
                 class="r-input__error-message"
            >
                {{ errors && errors[name] && errors[name][0] || '' }}
            </div>
        </div>
    </div>
</template>

<script>
import {BSpinner} from "bootstrap-vue"
import Multiselect from 'vue-multiselect'
import SimpleScrollbar from 'simple-scrollbar'

export default {
        name: 'DropDownFilter',

        components: {
            Multiselect,
            BSpinner
        },

        props: {
            specs: {
                type: Array,
                default: () => [],
            },
            noOptionsText: {
              type: String,
              default: ''
            },
            closeOnSelect: {
              type: Boolean,
              default: false
            },
            state: {
                type: Array,
                default: () => [],
            },
            facets: {
                type: Array,
                default: () => [],
            },
            placeholder: {
                type: String,
                default: 'Выберите',
            },
            label: {
                type: String,
                required: false,
            },
            name: {
                type: String,
                required: true,
            },
            onChange: Function,
            defineClass: {
                type: String,
                default: 'r-drop-down',
            },
            blockClass: {
                type: String,
                default: '',
            },
            isRemovable: {
              type: Boolean
            },
            openBottom: {
                type: Boolean,
                default: false,
            },
            trackBy: {
                type: String,
                default: 'pk',
            },
            labelName: {
                type: String,
                default: 'name',
            },
            multiple: {
                type: Boolean,
                default: true,
            },
            allowEmpty: {
                type: Boolean,
                default: true,
            },
            errors: {
                type: Object,
                default: () => ({}),
            },
            withError: Boolean,
            isNoResult: {
                type: Boolean,
                default: false,
            },
            noResultText: {
                type: String,
                default: 'Нет данных'
            },
            isSearchable: {
                type: Boolean,
                default: false
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            isAjaxSearchable: {
              type: Boolean,
              default: false,
            },
            ajaxRequest: {
              type: Function
            },
            internalSearch: {
              type: Boolean,
              default: false
            },
            isPaginated: {
              type: Boolean,
              default: false
            },
            emitWithName: {
              type: Boolean,
              default: false,
            }
        },

        data() {
            return {
                value: [],
                opened: false,
                searchValue: '',
                ajaxOptions: [],
                isSearching: false,
            };
        },

        watch: {
          facets() {
            this.isSearching = false;
            if (this.state && this.state.length) {
              this.value = this.facets.filter(opt => this.state.includes(opt[this.trackBy]));
            }
          }
        },

        computed: {
            statePlaceholder() {
              if(!this.isSearchable) return this.placeholder || 'Выберите'
              return 'Выберите или начните поиск'
            },

            proxyValue() {
              if(Array.isArray(this.value)) return this.value;
              if(!this.value) return [];

              const arr = [];
              arr.push(this.value)
              return arr;
            },
            valueArray() {
                let arr = [];

                if (Array.isArray(this.proxyValue)) {
                    this.proxyValue.forEach(item => {
                        arr.push(item[this.trackBy]);
                    });
                } else {
                  arr.push(this.proxyValue[this.trackBy]);
                }

                return arr;
            },

            valueList() {
                let label = '';

                this.proxyValue.forEach(item => {
                    if (this.facets.includes(item[this.trackBy]))
                        label += `${item[this.labelName]}, `;
                });

                if (!label) {
                    label = this.proxyValue.reduce((acc, item) => acc += `${item[this.labelName]}, `, '');
                }

                return label.slice(0, -2);
            },

            initialValue() {
              if (!(this.facets && this.facets.length) || !(this.state && this.state.length)) return []
              return [].concat.apply([], this.facets.filter(opt => this.state.includes(opt[this.trackBy])));
            },
        },

        created() {
          this.$parent.$on('resetFilters', () => {
            this.value = [];
          })

          if(this.isAjaxSearchable) {
            this.initAjaxOptions();
          }

          if (this.initialValue && this.initialValue.length) {
            this.value = this.initialValue;
          }
        },

        updated() {
          this.scrollContainerFix();
        },

        mounted() {
            if (this.state && this.state.length) {
              this.value = this.facets.filter(opt => this.state.includes(opt[this.trackBy]));
            }

            this.initScroll();
        },

        methods: {
          remove(e, id, props) {
            e.stopPropagation()
            this.$emit('remove', id);
            props.option.id = -1;
          },
            optionSelectHandler(e) {
                // Ограничение ширины, чтобы фильтр не разъезжался, когда выбрано большое количество пунктов в селекте
                // const placeHolderToFix = $(this.$refs.placeHolder);
                // const placeHolderToFixWidth = placeHolderToFix.outerWidth();
                // placeHolderToFix.css('width', placeHolderToFixWidth);
            },

            changeHandler() {
                // if (!this.proxyValue || this.proxyValue?.length === 0) {
                //     $(this.$refs.placeHolder).css('width', 'auto');
                // }

                if (this.withError) {
                    this.$emit('remove-error', this.name);
                }
                if(this.onChange && this.emitWithName) {
                  this.onChange(this.value, this.name);
                } else if (this.onChange) this.onChange(this.valueArray, this.name);
            },

            searchHandler(searchQuery) {
              if(!(searchQuery === '' || searchQuery.length >= 2)) return;
              this.isSearching = true;
              this.$emit('search', [this.name, searchQuery])
            },

            initAjaxOptions() {
              this.ajaxRequest()
                  .then(res => this.ajaxOptions = res.data.data)
                  .catch(e => console.log(e))
            },

            initScroll() {
                let optionListWrap = this.$refs['drop-down-filter'].querySelector('.multiselect__content-wrapper');
                optionListWrap.setAttribute('ss-container', true);
                SimpleScrollbar.initAll();

                if(this.isPaginated) {
                  this.$nextTick(() => {
                    const list = this.$refs['multiselect'].$el.querySelector('.ss-content');
                    const ul = this.$refs['multiselect'].$el.querySelector('ul');

                    const observer = (e) => {
                      if ((e.target.scrollTop + e.target.clientHeight >= (ul.clientHeight - 20))) {
                        // if(!this.lists[this.name][1].value) return;
                        // this.isSearching = true;
                        this.$emit('paginate', this.name);
                      }
                    }

                    list.addEventListener('scroll', observer);
                  })
                }
            },

            scrollContainerFix(timeout = 100) {
                let optionListWrap = this.$refs['drop-down-filter'].querySelector('.multiselect__content-wrapper');
                let optionList = this.$refs['drop-down-filter'].querySelector('.multiselect__content');


                setTimeout(() => {
                    optionListWrap.style.height = optionList.clientHeight + 'px';
                }, timeout);
            },

            openSelectList() {
                this.opened = !this.opened;

                if (!this.opened) return;

                this.scrollContainerFix();
            },

            close() {
                this.$refs.multiselect.toggle();
            }
        },
    };
</script>

<style lang="scss">
$primary: #7367f0;

.multiselect__tags {
  border: 1px solid #d8d6de !important;
}

.multiselect__spinner {
  width: 20px !important;
  right: 36px !important;
  z-index: 999;

  &:before {
    border-top-color: $primary !important;
  }

  &:after {
    border-top-color: $primary !important;
  }
}
.r-filter-cell {
  flex: 1 0 0;

  &._removable {

    & .multiselect__option {
      padding: 0;
    }

    & .removable-el {
      position: relative;
      padding: 12px 12px 12px 12px;

      & p {
        margin-top: 0;
        margin-bottom: 0;
      }

      & .input-cancel {
        position: absolute;
        right: 16px;
        top: 16px;
        z-index: 99;
      }

      & .input-spinner {
        background: white;
        z-index: 100;
        position: absolute;
        right: 16px;
        top: 16px;
        pointer-events: none;
      }
    }
  }

  &._hide {
    pointer-events: none;
    opacity: .4;
  }
}
</style>
