var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"drop-down-filter",staticClass:"r-filter-cell",class:[{'_removable': _vm.isRemovable, '_changed' : _vm.proxyValue && _vm.proxyValue.length && (_vm.proxyValue[0] && _vm.proxyValue[0].pk !== 'any') || !_vm.multiple && _vm.proxyValue && !Array.isArray(_vm.proxyValue), '_placeholder' : _vm.placeholder, '_opened' : _vm.opened, '_hide' : _vm.facets.length === 0 || _vm.disabled, '_searchable': _vm.isSearchable}, _vm.blockClass]},[(_vm.label)?_c('div',{staticClass:"r-filter-cell__label",class:{'_hide' : _vm.facets.length === 0}},[_vm._v(_vm._s(_vm.label)+" ")]):_vm._e(),_c('div',{staticClass:"r-filter-cell__bottom",class:[
            {
                '_changed' : _vm.proxyValue && _vm.proxyValue.length,
                '_hide' : _vm.facets.length === 0,
                '_with-error': _vm.withError,
                '_wrong': _vm.withError && _vm.errors && _vm.errors[_vm.name] && _vm.errors[_vm.name][0],
                '_search': _vm.isSearchable
            },
            _vm.defineClass ]},[_c('multiselect',{ref:"multiselect",attrs:{"options":_vm.isAjaxSearchable ? _vm.ajaxOptions : _vm.facets,"show-labels":false,"show-pointer":false,"hide-selected":false,"searchable":_vm.isSearchable,"multiple":_vm.multiple,"close-on-select":_vm.closeOnSelect,"placeholder":_vm.statePlaceholder,"open-direction":_vm.openBottom ? 'bottom' : '',"label":_vm.labelName,"track-by":_vm.trackBy,"internal-search":_vm.internalSearch,"clear-on-select":_vm.multiple,"show-no-results":_vm.isNoResult,"selected-label":'any',"allow-empty":_vm.allowEmpty},on:{"input":_vm.changeHandler,"open":_vm.openSelectList,"close":function($event){_vm.opened = !_vm.opened},"select":_vm.optionSelectHandler,"search-change":_vm.searchHandler},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
        var values = ref.values;
        var search = ref.search;
        var isOpen = ref.isOpen;
return [(_vm.proxyValue && _vm.proxyValue.length && _vm.multiple)?_c('div',{ref:"placeHolder",staticClass:"select__labels",class:{'_hide' : _vm.facets.length === 0}},[_vm._v(" "+_vm._s(_vm.valueList)+" ")]):_vm._e()]}},{key:"option",fn:function(props){return (_vm.isRemovable)?[_c('div',{staticClass:"d-flex justify-content-between removable-el align-items-center"},[_c('p',[_vm._v(_vm._s(props.option.name))]),(!props.option.is_default)?_c('feather-icon',{staticClass:"input-cancel cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":function($event){return _vm.remove($event, props.option.id, props)}}}):_vm._e(),_c('BSpinner',{directives:[{name:"show",rawName:"v-show",value:(props.option.id === -1),expression:"props.option.id === -1"}],staticClass:"input-spinner",attrs:{"variant":"primary","small":""}})],1)]:undefined}},{key:"caret",fn:function(ref){
        var toggle = ref.toggle;
return (_vm.isSearchable)?[_c('div',{class:['multiselect__search-clear', {'_active': _vm.searchValue}]}),(_vm.isSearching)?_c('div',{staticClass:"multiselect__spinner"}):_vm._e(),_c('div',{staticClass:"multiselect__select",on:{"mousedown":function($event){$event.preventDefault();$event.stopPropagation();return toggle()}}})]:undefined}}],null,true),model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_c('template',{slot:"noResult"},[_c('div',[_vm._v(_vm._s(_vm.noResultText))])]),(_vm.noOptionsText)?_c('template',{slot:"noOptions"},[_c('div',[_vm._v(_vm._s(_vm.noOptionsText))])]):_vm._e()],2),(_vm.withError && _vm.errors && _vm.errors[_vm.name] && _vm.errors[_vm.name][0])?_c('div',{staticClass:"r-input__error-message"},[_vm._v(" "+_vm._s(_vm.errors && _vm.errors[_vm.name] && _vm.errors[_vm.name][0] || '')+" ")]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }