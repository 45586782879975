<template>
  <div>
    <div v-if="isLoading" class="d-flex justify-content-center py-4">
      <b-spinner variant="primary" label="Spinning" />
    </div>
    <div v-else-if="error" class="d-flex justify-content-center py-4">
      <p class="text-danger">Не удалось загрузить данные</p>
    </div>
    <div v-else>
      <slot />
      <div v-if="isLoadingMore" class="d-flex justify-content-center py-4">
        <b-spinner variant="primary" label="Spinning" />
      </div>
    </div>

  </div>
</template>

<script>
import { BSpinner } from 'bootstrap-vue'

export default {
  components: { BSpinner },
  props: {
    isLoading: {
      type: Boolean,
      required: true
    },
    error: {
      type: Boolean,
      required: true
    },
    isLoadingMore: {
      type: Boolean
    }
  }
}
</script>
